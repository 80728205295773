import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box } from '@material-ui/core';
// import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
// import DateFnsUtils from '@date-io/date-fns';
// import { useGDispatch, useGState, ga } from 'state/store';
// import { useGState } from 'state/store';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/CloseRounded';
import MenuIcon from '@material-ui/icons/MenuRounded';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    backgroundColor: '#212121',
    padding: 8,
    ...theme.mixins.toolbar,
  },
  sidebarToggleButton: {
    padding: 8,
    color: 'white',
  },
}));

export default function PlanAdditionalActions({ drawerState, setDrawerState }) {
  const classes = useStyles();
  // const gDispatch = useGDispatch();
  // const {
  //   date: { date },
  // } = useGState(state => ({ date: state.date }));

  // console.log({ date });

  return (
    <Box zIndex="tooltip">
      <div className={classes.toolbar}>
        <IconButton
          onClick={() => {
            setDrawerState((s) => !s);
          }}
          className={classes.sidebarToggleButton}
        >
          {drawerState ? <Close fontSize="large" /> : <MenuIcon fontSize="large" />}
        </IconButton>
      </div>

      {/* <div className={classes.datePickerContainer}>
        <MuiPickersUtilsProvider
          utils={DateFnsUtils}
          marginDense
          classes={{ root: classes.datePicker }}
        >
          <DatePicker
            variant="inline"
            inputVariant="outlined"
            default={new Date()}
            // format={'do'}
            autoOk
            // value={date}
            onChange={newDate => {
              gDispatch({ type: ga.DATE, date: newDate });
            }}
          />
        </MuiPickersUtilsProvider>
      </div> */}
    </Box>
  );
}
