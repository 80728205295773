import { debug, createNewPath, FixedLengthArrayPool } from './utils';
import runSa from './runSa';
import nearestN from './nearestN';

export const pathCost = (path, calcCost) => {
  let sum = 0;
  for (let i = 0; i < path.length - 1; i++) {
    const cost = calcCost(path[i], path[i + 1]);
    sum += cost <= 200 ? cost * 0.1 : cost; // making the process a bit more greedy by rewarding paths with short segments
  }
  return sum;
};

const defaultOpts = {
  N: 100000,
  reheatInterval: 12000,
  T: 110,
  lambda: 0.999,
  round: 1,
};

const solveTsp = (N, calcCost, roundtrip, opts = defaultOpts) => {
  const statePool = new FixedLengthArrayPool(N + (roundtrip ? 1 : 0));

  debug('Construction using NearestN');
  const path = nearestN(N, calcCost, roundtrip, statePool);

  if (opts.N === 0 || path.length < 4) {
    // already the best path
    return path;
  }

  debug('Local search using SA');
  const answer = runSa(path, (path) => pathCost(path, calcCost), createNewPath, statePool, opts);

  return answer.final.sol;
};

export default solveTsp;
