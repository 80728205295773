import React from 'react';
import Layout from 'components/Layout';
import { Router } from '@reach/router';
import PlanningDashboard from 'delivery/planning/PlanningDashboard';

export default function plan(props) {
  return (
    <Layout {...props} hideFilters>
      <Router>
        <PlanningDashboard path="/dl/plan/:id" />
      </Router>
    </Layout>
  );
}
