import React, { useState } from 'react';
import { LoadScript } from '@react-google-maps/api';
import { useGState, ga } from 'state/store';

const libraries = ['geometry', 'drawing', 'places'];

function Maps({ render, height = '600px' }) {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const GOOGLE_API_KEY = useGState((s) => s[ga.PANEL_CONFIG].GOOGLE_API_KEY);

  if (!GOOGLE_API_KEY) return <div>Google Maps API key not provided</div>;

  return (
    <div style={{ height: height }}>
      <LoadScript
        id="script-loader"
        googleMapsApiKey={GOOGLE_API_KEY}
        loadingElement={<div style={{ height: `100%` }} />}
        libraries={libraries}
        onLoad={() => {
          setScriptLoaded(true);
        }}
      >
        {scriptLoaded && render()}
      </LoadScript>
    </div>
  );
}

export default Maps;
