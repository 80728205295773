import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import { DialogTransition } from './transitions';

export default class ConfirmationDialog extends React.Component {
  state = {
    open: false,
    params: null,
  };

  openDialog = (params) => {
    this.setState({ open: true, params });
  };

  closeDialog = () => {
    this.setState({ open: false });
  };

  render() {
    const {
      title = 'Are you Sure',
      desc,
      yes = 'YES',
      no = 'NO',
      onYes,
      closeOnYes = true,
    } = this.props;
    return (
      <Dialog
        open={this.state.open}
        TransitionComponent={DialogTransition}
        keepMounted
        onClose={this.closeDialog}
      >
        <DialogTitle>{title || 'Are you Sure'}</DialogTitle>
        <DialogContent>
          <DialogContentText>{desc}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.closeDialog} color="primary">
            {no}
          </Button>
          <Button
            onClick={() => {
              if (closeOnYes) this.closeDialog();
              onYes(this.state.params);
            }}
            color="primary"
            variant="outlined"
          >
            {yes}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
