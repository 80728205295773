// eslint-disable-next-line no-unused-vars
export const debug = (...msg) => {
  // console.log(...msg);
};

export const inversion = (path, a, b, pathPool) => {
  const newpath = pathPool.get();

  let i = 0,
    j = 0;
  for (; i < a; i++, j++) {
    newpath[j] = path[i];
  }
  for (i = b - 1; i >= a; i--, j++) {
    newpath[j] = path[i];
  }
  for (i = b; i < path.length; i++, j++) {
    newpath[j] = path[i];
  }

  return newpath;
};

export const translation = (path, a, b, pathPool) => {
  const newpath = pathPool.get();

  let i = 0,
    j = 0;
  for (; i < a; i++, j++) {
    newpath[j] = path[i];
  }
  newpath[j++] = path[b - 1];
  for (i = a; i < b - 1; i++, j++) {
    newpath[j] = path[i];
  }
  for (i = b; i < path.length; i++, j++) {
    newpath[j] = path[i];
  }

  return newpath;
};

export const switching = (path, a, b, pathPool) => {
  const newpath = pathPool.get();

  let i = 0,
    j = 0;
  for (; i < a; i++, j++) {
    newpath[j] = path[i];
  }
  newpath[j++] = path[b - 1];
  for (i = a + 1; i < b - 1; i++, j++) {
    newpath[j] = path[i];
  }
  newpath[j++] = path[a];
  for (i = b; i < path.length; i++, j++) {
    newpath[j] = path[i];
  }

  return newpath;
};

export const getRandomIndex = (start, end) => {
  return start + Math.floor(Math.random() * (end - start));
};

//ensures endpoints are not involved in manipulation
//assumes path.length >= 4 and k >= 2
export const createNewPath = (path, pathPool) => {
  const k = 2; // k >= 2

  const a = getRandomIndex(1, path.length - k);
  const b = a + getRandomIndex(2, path.length - a);

  const roll = Math.random();

  if (roll < 0.4) {
    return inversion(path, a, b, pathPool);
  } else if (roll < 0.4 + 0.4) {
    return translation(path, a, b, pathPool);
  } else {
    return switching(path, a, b, pathPool);
  }
};

export class FixedLengthArrayPool {
  constructor(length) {
    this.arrayLength = length;
    this.pool = [];
    this.allocCount = 0;
  }

  create() {
    const arr = [];
    arr.length = this.arrayLength;
    return arr;
  }

  init(poolSize) {
    for (let i = 0; i < poolSize; i++) {
      this.pool.push(this.create());
    }
  }

  get() {
    if (this.pool.length === 0) {
      debug('Allocating new array!', ++this.allocCount);
      return this.create();
    } else {
      return this.pool.pop();
    }
  }

  reclaim(arr) {
    if (arr.length !== this.arrayLength) {
      throw new Error(`This pool only expects arrays of length ${this.arrayLength}`);
    }
    if (this.pool.indexOf(arr) === -1) {
      this.pool.push(arr);
    }
  }
}
